import React from 'react';
import { Route } from 'react-router';

const PatientWelcomePage = React.lazy(() => import('../components/patientWelcomePage'));

const PatientNavigation = React.memo(() => {
    return (
        <>
            <Route path='/'>
                <PatientWelcomePage />
            </Route>
        </>
    )
});

export default PatientNavigation;
