import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export const USER_SET = 'USER/SET';
export const USER_SIGNOUT = 'USER/SIGNOUT';

const initialState = {};

export const reducer = (state = initialState, action) => {

    switch (action.type) {
        case USER_SET:
            return {
                ...state,
                ...action.user
            }
        case USER_SIGNOUT:
            return {
            }
        default:
            return state;
    }
}

export const useSetUserCallback = () => {

    const dispatch = useDispatch();

    return useCallback((profile) => {

        dispatch({ type: USER_SET, user: profile });
    }, [dispatch]);
}

export const useSignOutUserCallback = () => {

    const dispatch = useDispatch();

    return useCallback(() => {

        dispatch({ type: USER_SIGNOUT });
    }, [dispatch]);
}

export const useUser = () => {

    const user = useSelector(state => state.user);
    return user;
}
