import React from 'react';
import { Route } from 'react-router';

const UsersListPage = React.lazy(() => import('../components/users/List'));
const UsersEditPage = React.lazy(() => import('../components/users/Edit'));
const PatientsPage = React.lazy(() => import('../components/patients/List'));
const Dashboard = React.lazy(() => import('../components/dashboard'));
const PatientDetailsPage = React.lazy(() => import('../components/patients/Details'));
const ClinicsList = React.lazy(() => import('../components/clinics/List'));
const EditClinic = React.lazy(() => import('../components/clinics/Edit'));

const ClinicAdminNavigation = React.memo(() => {
    return (
        <>
            <Route exact path='/'>
                <Dashboard />
            </Route>

            <Route exact path='/users'>
                <UsersListPage />
            </Route>

            <Route exact path='/users/create'>
                <UsersEditPage />
            </Route>

            <Route exact path='/users/edit/:id'>
                <UsersEditPage />
            </Route>

            <Route exact path="/clinics">
                <ClinicsList />
            </Route>

            <Route path="/clinics/edit/:id">
                <EditClinic />
            </Route>

            <Route exact path='/patients/details/:id'>
                <PatientDetailsPage />
            </Route>

            <Route exact path="/patients/create">
                <PatientDetailsPage />
            </Route>

            <Route exact path='/patients'>
                <PatientsPage />
            </Route>
        </>
    )
})

export default ClinicAdminNavigation;
