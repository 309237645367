import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router';
import { NotificationContainer } from 'react-notifications';
import Layout from './components/layout/Layout';
import NotFound from './components/shared/NotFound';
import LoadingSpinner from './components/shared/LoadingSpinner';
import Navigation from './navigation';



const App = () => {
    return <>
        <Layout>
            <Suspense fallback={<LoadingSpinner />}>
                <Switch>
                    <Navigation />

                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            </Suspense>
        </Layout>

        <NotificationContainer />
    </>
}

export default App;
