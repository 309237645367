import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import {
    BrowserRouter as Router
} from "react-router-dom";

import './assets/css/main.css';
import 'react-notifications/lib/notifications.css';


import configureStore from './store/configureStore';
import Authorizor from './Authorizor';
const store = configureStore({});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Authorizor>
                    <App />
                </Authorizor>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
