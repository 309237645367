export const useDefaultUser = () => ({
    email: '',
    firstName: '',
    lastName: '',
    role: 0,
    clinicId: 0
})

export const USER_ROLES = {
    PHYSICIAN: 0,
    ADMIN: 1,
    PHYSICIANS_ASSISTANT: 2,
    NURSE: 3,
    MEDICAL_ASSISTANT: 4,
    PATIENT: 5,
    CLINIC_ADMIN: 6
}

export const getRoleNameByType = (type) => {
    switch (type) {
        case USER_ROLES.PHYSICIAN: return "Physician";
        case USER_ROLES.ADMIN: return "Admin";
        case USER_ROLES.PHYSICIANS_ASSISTANT: return "Physicians Assistant";
        case USER_ROLES.NURSE: return "Nurse";
        case USER_ROLES.MEDICAL_ASSISTANT: return "Medical Assistant";
        case USER_ROLES.PATIENT: return "Patient";
        case USER_ROLES.CLINIC_ADMIN: return "Clinic Admin";
        default: return ""
    }
}