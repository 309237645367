import React from 'react';
import { useUser } from '../store/user';
import { USER_ROLES } from '../utils/user';
import AdminNavigation from './AdminNavigation';
import ClinicAdminNavigation from './ClinicAdminNavigation';
import PatientNavigation from './PatientNavigation';
import UserNavigation from './UserNavigation';

const Navigation = React.memo(() => {
    const user = useUser();

    if (user.role === USER_ROLES.ADMIN) {
        return <AdminNavigation />
    } else if (user.role === USER_ROLES.CLINIC_ADMIN) {
        return <ClinicAdminNavigation />
    } else if (user.role === USER_ROLES.PATIENT) {
        return <PatientNavigation />
    }
    return <UserNavigation />
})

export default Navigation;