import React from 'react';
import { Route } from 'react-router';
const PatientsPage = React.lazy(() => import('../components/patients/List'));
const Dashboard = React.lazy(() => import('../components/dashboard'));
const PatientDetailsPage = React.lazy(() => import('../components/patients/Details'));

const UserNavigation = React.memo(() => {
    return (
        <>
            <Route exact path='/'>
                <Dashboard />
            </Route>

            <Route exact path='/patients/details/:id'>
                <PatientDetailsPage />
            </Route>

            <Route exact path="/patients/create">
                <PatientDetailsPage />
            </Route>

            <Route exact path='/patients'>
                <PatientsPage />
            </Route>
        </>
    )
})

export default UserNavigation;
