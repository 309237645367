import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { API_URL } from '../constants';

const dataOnly = (response) => response.data;

const createApiInstance = (baseURL) => {

    const instance = axios.create({
        baseURL
    });


    instance.interceptors.response.use(dataOnly, (error) => {
        console.log(error);
        if (error.response.status === 401) {

        }
        else if (error.response.status === 400) {

            // errors is an object, each key is an array of strings
            try {
                const errors = error.response.data.map(item => item.description).reduce((acc, err) => acc + '\n' + err);
                NotificationManager.error("Error", errors.toString());
            } catch {
                //NotificationManager.error("Error", "Something went wrong");
            }
            
        }
        else {

            const msg = error.response.data && error.response.data.message;
            NotificationManager.error('Error', msg || 'Error while contacting the server');
        }

        return Promise.reject(error);

    });


    return instance;
}



export default createApiInstance(API_URL);
