import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { signOut } from '../../ajax/account';
import * as Icons from '../../assets/img';
import { useSignOutUserCallback, useUser } from '../../store/user';
import { getUserNavConfig } from './navigationConfiguration';

const SideNavItem = ({ to, img, label, exact, active }) => (
    <NavLink to={to} exact={exact} className={active ? "active" : null}>
        <img src={img} alt="nav-item" />
        <span className="sidenav-label">{label}</span>
    </NavLink>
);

const UserSection = ({ onSignOut }) => (
    <div className="logout" onClick={onSignOut}>
        <img src={Icons.logoutIc} alt="nav-item" />
        <span className="sidenav-label">Log Out</span>
    </div>
);

const SideNav = () => {
    const location = useLocation();
    const signOutUser = useSignOutUserCallback();
    const user = useUser();

    const onSignOut = () => {
        signOut().then(() => {
            signOutUser();
        })
    }

    console.log(user);
    return (
        <div id="sidenav" className="sidenav">
            <div>
                <img src={Icons.logoDarkIc} alt="logo" className="logo" />
            </div>
            <div className="nav-items-container">
                {getUserNavConfig(user.role).map(item => (
                    <SideNavItem
                        key={item.label}
                        to={item.to}
                        img={item.image}
                        label={item.label}
                        exact={item.exact}
                        active={location === item.to}
                    />
                ))}
            </div>
            <UserSection
                onSignOut={onSignOut}
            />
        </div>
    )
}

export default SideNav;
