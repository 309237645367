import { useCallback } from 'react';
import { useSetUserCallback, useSignOutUserCallback } from '../store/user';
import { getProfile, signOut, login }  from '../ajax/account';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';


export const useSignOut = () => {

    const signOutUserCallback = useSignOutUserCallback();

    const signOutCallback = useCallback(async () => {
        try {

            await signOut();
            signOutUserCallback();
        }
        catch (resp) {
            return Promise.reject(resp.response);
        }

    }, [signOutUserCallback]);

    return signOutCallback;
}


export const useSignIn = (returnUrl = '/') => {

    const history = useHistory();
    const setUser = useSetUserCallback();

    const signIn = useCallback(async (values) => {

        try {
            await login(values);
            const profile = await getProfile();
            setUser(profile);
            history.push(returnUrl);
        }
        catch (resp) {
            console.log(resp);
            return Promise.reject(resp.response);
        }

    }, [history, setUser, returnUrl]);


    return [signIn];
}


export const useAuthentication = (returnUrl = '/') => {

    const user = useSelector(state => state.user);
    const isAuthenticated = useMemo(() => {
        return 'userAccountId' in user;
    }, [user]);



    //useEffect(() => {

    //    const action = async () => {

    //        setIsAuthenticating(true);
    //        setInitialLoad(false);
    //        try {
    //            const user = await getProfile();
    //            setIsAuthenticated(true);
    //            setUser(user);
    //        }
    //        catch (resp) {
    //            if (resp.response && resp.response.status !== 401) {
    //                NotificationManager.error('', 'Error contacting server');
    //            }
    //        }
    //        finally {
    //            setIsAuthenticating(false);
    //        }
    //    }

    //    if (initialLoad) {
    //        action();
    //    }
    //}, [initialLoad, setUser, returnUrl]);

    return [isAuthenticated];
}
