import React from 'react';
import PropTypes from 'prop-types';
import SideNav from './SideNav';

const Layout = ({ children }) => {
    return (
        <>
            <div className="main-layout">
                <div className="row g-0 bg-light">
                    <div className="col-2">
                        <SideNav />
                    </div>
                    <div id="content-container" className="col-10">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default Layout;
