import React, { useRef, useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLocation } from 'react-router';
import { useAuthentication } from './utils/auth';
import LoadingSpinner from './components/shared/LoadingSpinner';
import LoginApp from './LoginApp';
import { useSetUserCallback } from './store/user';
import { getProfile } from './ajax/account';

export const useGetData = () => {

    const [initialLoad, setInitialLoad] = useState(true);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const setUser = useSetUserCallback();


    useEffect(() => {

        const action = async () => {

            setIsAuthenticating(true);
            setInitialLoad(false);
            try {
                const user = await getProfile();
                setUser(user);
            }
            catch (resp) {
                if (resp.response && resp.response.status !== 401) {
                    NotificationManager.error('', 'Error contacting server');
                }
            }
            finally {
                setIsAuthenticating(false);
            }
        }

        if (initialLoad) {
            action();
        }
    }, [initialLoad, setUser, setIsAuthenticating, setInitialLoad]);

    return [isAuthenticating];
}

export default ({ children }) => {

    const { pathname, search } = useLocation();
    const launchUrlRef = useRef(`${pathname}${search}`);

    const [isAuthenticating] = useGetData(launchUrlRef.current);
    const [isAuthenticated] = useAuthentication();

    if (isAuthenticating) {
        return <LoadingSpinner loading={isAuthenticating} />
    }

    if (!isAuthenticated) {
        return <LoginApp />
    }

    return <>{children}</>;
}