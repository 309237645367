import * as Icons from '../../assets/img';
import { USER_ROLES } from '../../utils/user';

const adminNavConfig = [
    {
        to: "/",
        image: Icons.dashboardIc,
        label: 'Dashboard',
        exact: true,
    },
    {
        to: '/users',
        image: Icons.usersIc,
        label: "Users",
        exact: true
    },
    {
        to: '/clinics',
        image: Icons.clinicIc,
        label: "Clinics",
        exact: true
    },
    {
        to: '/icd_codes',
        image: Icons.listIc,
        label: 'ICD Codes',
        exact: true
    }
];

const clinicAdminNavConfig = [
    {
        to: "/",
        image: Icons.dashboardIc,
        label: 'Dashboard',
        exact: true,
    },
    {
        to: '/users',
        image: Icons.usersIc,
        label: "Users",
        exact: true
    },
    {
        to: '/clinics',
        image: Icons.clinicIc,
        label: "Clinics",
        exact: true
    },
    {
        to: '/patients',
        image: Icons.patientsIc,
        label: "Patients",
        exact: true
    }
]

const userNavConfig = [
    {
        to: "/",
        image: Icons.dashboardIc,
        label: 'Dashboard',
        exact: true,
    },
    {
        to: '/patients',
        image: Icons.patientsIc,
        label: "Patients",
        exact: true
    }
]

const patientNavConfig = [];

export const getUserNavConfig = (userRole) => {
    if (userRole === USER_ROLES.ADMIN) return adminNavConfig;
    if (userRole === USER_ROLES.CLINIC_ADMIN) return clinicAdminNavConfig;
    if (userRole === USER_ROLES.PATIENT) return patientNavConfig;
    return userNavConfig;
}