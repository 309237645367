import React from 'react';
import { Route } from 'react-router';

const UsersListPage = React.lazy(() => import('../components/users/List'));
const UsersEditPage = React.lazy(() => import('../components/users/Edit'));
const AdminDashboard = React.lazy(() => import('../components/adminDashboard'));
const ClinicsList = React.lazy(() => import('../components/clinics/List'));
const EditClinic = React.lazy(() => import('../components/clinics/Edit'));
const IcdCodes = React.lazy(() => import("../components/icdCodes"));

const AdminNavigation = React.memo(() => {
    return (
        <>
            <Route exact path='/'>
                <AdminDashboard />
            </Route>

            <Route exact path='/users'>
                <UsersListPage userIsAdmin />
            </Route>

            <Route exact path='/users/create'>
                <UsersEditPage userIsAdmin />
            </Route>

            <Route exact path='/users/edit/:id'>
                <UsersEditPage userIsAdmin />
            </Route>

            <Route exact path="/clinics">
                <ClinicsList />
            </Route>

            <Route path="/icd_codes" exact>
                <IcdCodes />
            </Route>

            <Route exact path="/clinics/create">
                <EditClinic />
            </Route>

            <Route path="/clinics/edit/:id">
                <EditClinic />
            </Route>

            
        </>
    )
});

export default AdminNavigation;
