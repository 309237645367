import axios from './handler';

export const getProfile = () => {
    return axios.get('/account/profile');
};

export const login = (values) => {
    return axios.post('/account', values);
};

export const signOut = () => {
    return axios.get('/account/sign-out');
}

export const getUsers = () => {
    return axios.get('/account/list')
}

export const getClinicUsers = (cliinicId) => {
    return axios.get(`/account/listClinic?clinicId=${cliinicId}`)
}

export const saveNewUser = (user) => {
    return axios.post('/account/register', user)
}

export const deleteUser = (id) => {
    return axios.delete(`/account/${id}`)
}

export const editUser = (user) => {
    return axios.patch('/account', user);
}

export const getUser = (id) => {
    return axios.get(`account/${id}`);
}

export const forgotPassword = (values) => {
    return axios.post('/account/forgot-password', values);
}

export const resetPassword = (values) => {
    return axios.post('/account/reset-password', values);
}

export const changePassword = (values) => {
    return axios.post('/account/change-password', values);
}

export const changeEmail = (values) => {
    return axios.post('/account/change-email', values);
}

export const setPassword = (model) => {
    return axios.post('/account/set-password', model);
}