import React, { Suspense } from 'react';
import { NotificationContainer } from 'react-notifications';
import LoadingSpinner from './components/shared/LoadingSpinner';

const Login = React.lazy(() => import('./components/account'));

const LoginApp = () => {


    return <>
        <Suspense fallback={<LoadingSpinner />}>
            <Login />
        </Suspense>

        <NotificationContainer />
    </>
}

export default LoginApp;
